<template>
    <div class='videoList'>
        <div class="list">
            <div class="item">
                <div class="add-card" @click="toCreate">
                    <div class="add-handle">
                        <img src="@/assets/images/creation/creationMng_(31).png" alt="">
                        <div class="new-blank"> 新建空白 </div>
                    </div>
                </div>
            </div>
            <div class="item" v-for="(item, index) in list" :key="index">
                <div class="creation-card">
                    <div class="audio-container">
                        <img v-if="item.production_file && item.production_file.length" :src="item.production_file[0]"
                            alt="" class="creation-cover-img">
                        <!-- <div class="right-top p-edit" v-if="item.status == 1">创作中</div>
                        <div class="right-top p-progress" v-if="item.status == 2">"排队中</div> -->
                        <div class="right-top p-progress" v-if="item.status == 3">合成中</div>
                        <!-- <div class="right-top p-err" v-if="item.status == 5">创作失败</div> -->
                    </div>
                    <div class="card-info">
                        <div class="left">
                            <edit-name :name="item.name" :id="item.id" @toDetail="toDetail"
                                @editName="nameChange"></edit-name>
                            <div class="creation-time">创建时间 {{ item.create_time }}</div>
                        </div>
                        <div class="right">
                            <el-popover placement="bottom-end" width="145" trigger="hover" popper-class="more-btn">
                                <div class="content">
                                    <div class="item" @click="toDetail(item)">查看详情</div>
                                    <div class="item" @click="copyUrl(item.production_file[0])">复制链接</div>
                                    <div class="item" @click="downloadFile(item.production_file, item.name)">下载</div>
                                    <div class="item c_e" @click="delBtn(item.id)">删除</div>
                                </div>
                                <span slot="reference" class="el-icon-more more"></span>
                            </el-popover>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 提示弹框 -->
        <point-out ref='dialogTip' type='account' @close='accountDelBtn' dialogTitle="提示" dialogWidth='380px'
            :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">作品删除后不可恢复，请确认是否删除？</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import EditName from '../editCreationName/index.vue'
import PointOut from '@/components/point_out/point_out.vue'//提示弹框

export default {
    components: {
        EditName,
        PointOut
    },
    props: {
        list: {
            type: Array,
            default: () => []
        },
        type: {
            type: Number,
            default: ''
        }
    },
    data() {
        return {
            id: null,
        }
    },
    computed: {},
    watch: {
    },
    created() {


    },
    mounted() { },
    methods: {
        // 详情
        toDetail(val) {
            let path = ''
            if (this.type == 1) { // 绘画
                path = '/workben/painting_details/'+val.id
            }
            if (this.type == 2) { // 漫画
                path = '/workben/comics_details/'+val.id
            }
            this.$router.push({
                path: path,
                query: {
                    name: val.name
                }
            })
        },
        // 修改名称
        nameChange(val) {
            console.log(val)
            if (this.type == 1) { // 绘画
                this.$creationApi.editDrawName({ name: val.name }, val.id).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        this.$emit('refreshBtn')
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }
            if (this.type == 2) { // 漫画
                this.$creationApi.editCartoonName({ name: val.name }, val.id).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        this.$emit('refreshBtn')
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }
        },
        // 复制
        copyUrl(val) {
            this.$copyText(val).then(
                (e) => {
                    this.$message.success("复制成功");
                },
                function (e) { }
            );
        },
        // 下载 多张
        downloadFile(val, name) {
            if (val && val.length) {
                for(let i = 0; i < val.length; i++) {
                    setTimeout(() => {
                        this.downloadMany(val[i], `${name}${Date.now()}`)
                    }, i * 1000)
    
                }
            }
        },
        downloadMany(url, name) {
            const a = document.createElement('a');
            // 解决图片跨域
            a.href = `${url}?response-content-type=application/octet-stream`;
            a.download = name || '';
            a.click();
            a.remove();
        },
        // 删除
        delBtn(id) {
            this.id = id
            this.$refs.dialogTip.openDialogBtn()
        },
        // 删除
        accountDelBtn() {
            if (this.type == 1) { // 删除绘画
                this.$creationApi.delDraw(this.id).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        this.$emit('refreshBtn')
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }
            if (this.type == 2) { // 删除漫画
                this.$creationApi.delCartoon(this.id).then(res => {
                    if (res.code == 1000) {
                        this.$succMsg(res.message)
                        this.$emit('refreshBtn')
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }

        },
        toCreate() {
            let path = ''
            if (this.type == 1) { // 绘画
                path = '/workben/painting'
            }
            if (this.type == 2) { // 漫画
                path = '/workben/comics'
            }
            this.$router.push({
                path: path,
            })
        }

    },
}
</script>

<style lang='scss' scoped>
.videoList {
    .list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-right: -30px;
        width: calc(1774px - 40px);

        .item {
            margin-right: 30px;

        }

        .add-card {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            position: relative;
            // margin-right: 30px;
            cursor: pointer;
            width: 314px;
            height: 178px;
            background: rgba(66, 94, 255, 0.08);
            border-radius: 10px;
            border: 1px dashed $blueColor1;
            margin-bottom: 20px;

            .add-handle {
                width: 314px;
                height: 178px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                img {
                    width: 48px;
                    height: 48px;
                    border-radius: 16px;
                }

                .new-blank {
                    width: 98px;
                    height: 32px;
                    font-size: 14px;
                    border-radius: 4px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: $blueColor1;
                }


            }
        }

        .creation-card {
            position: relative;
            // margin-right: 30px;
            margin-bottom: 20px;

            .audio-container {
                width: 315px;
                height: 178px;
                background: linear-gradient(180deg, #c9cbd8, #bcbdc7);
                text-align: center;
                border-radius: 10px;
                overflow: hidden;
            }

            .creation-cover-img {
                max-width: 100%;
                max-height: 100%;
            }

            .creation-cover {
                width: 315px;
                height: 178px;
                border-radius: 10px 10px 10px 10px;
                -o-object-fit: contain;
                object-fit: contain;
            }

            .operator_btn {
                position: absolute;
                top: calc(178px / 2 - 32px / 2);
                left: calc(315px / 2 - 68px / 2);
                width: 68px;
                height: 32px;
                border-radius: 4px;
                line-height: 32px;
                text-align: center;
                font-size: 12px;
            }

            .right-top {
                min-width: 48px;
                padding: 0 5px;
                line-height: 20px;
                // background: #612EF2;
                border-radius: 10px 0px 10px 0px;
                position: absolute;
                font-size: 12px;
                left: 0;
                top: 0;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                cursor: pointer;
            }

            .card-info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {

                    .edit-name {
                        display: flex;
                        align-items: center;
                        font-size: 14px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 28px;

                        .btn-edit {
                            margin-left: 10px;
                        }
                    }

                    .creation-time {
                        font-size: 12px;
                        font-weight: 400;
                        color: #666666;
                    }
                }

                .right {
                    .more {
                        font-size: 14px;
                        color: #333333;
                        transform: rotate(90deg);
                        cursor: pointer;

                    }
                }
            }
        }
    }

    .p-edit {
        background: linear-gradient(90deg, $blueColor1 0%, $blueColor2 100%);
        color: #fff;
    }

    .p-progress {
        background: #FF7432;
        color: #fff;
    }

    .p-paidui {
        background: #BB1AFD;
        color: #fff;
    }

    .p-progress-btn {
        color: #fff;
        border: 1px solid #fff;
    }

    .p-err {
        background: #E51D41;
        color: #fff;
    }

}
</style>
<style lang="scss">
.more-btn {
    // background-color: rgba($color: #fff, $alpha: 0.5);

    .content {
        .item {
            font-size: 11px;
            font-weight: 400;
            color: #333333;
            padding: 5px 10px;
            cursor: pointer;
        }

        .c_e {
            color: #F25353;
        }

        .item:hover {
            background: $blueColor1;
            color: #fff;

        }
    }

}
</style>
