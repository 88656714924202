<template>
	<div class='draw_works'>
		<div class="draw_works_top">
			<div class="item_box left_box">
				<div class="title">AI背景</div>
				<div class="num flex_box">
					<div>已完成：{{ draw_completed }}</div>
					<div>剩余次数：{{ drawing }}</div>
				</div>
			</div>
			<div class="item_box right_box">
				<div class="title">AI换脸</div>
				<div class="num flex_box">
					<div>已完成：{{ cartoon_completed }}</div>
					<div>剩余次数：{{ cartoon }}</div>
				</div>
			</div>
			<div class="buy_tip">
				<div class="txt">创作次数不够用？</div>
				<el-button class="btnBgColor_red" size="mini" round @click="toBuy">去订阅</el-button>
			</div>
		</div>
		<div class="con_bottom sec_wrap p20 mt20">
			<div class="tab">
				<div class="tabs_item" :class="tabC == item.value ? 'active' : ''" v-for="(item, index) in tabs"
					:key="index" @click="tabChange(item)">
					<div class="txt">{{ item.label }}</div>
					<div class="bar"></div>
				</div>
			</div>
			<div class="list_con p20">
				<div class="search_box">
					<div class="flex_box">
						<div class="form_item">
							<div class="label">画面大小</div>
							<el-select class="form_val" size="small" v-model="params.proportion" placeholder="请选择"
								@change="search">
								<el-option v-for="item in proportionList" :key="item.value" :label="item.value"
									:value="item.value" :clearable="true">
								</el-option>
							</el-select>
						</div>
						<div class="form_item ml10" v-if="tabC == 1">
							<div class="label">画面风格</div>
							<el-select class="form_val" size="small" v-model="params.style" placeholder="请选择"
								@change="search">
								<el-option v-for="item in styleList" :key="item.id" :label="item.name" :value="item.id"
									:clearable="true">
								</el-option>
							</el-select>
						</div>
						<div class="form_item ml10" v-if="tabC == 1">
							<div class="label">艺术家</div>
							<el-select class="form_val" size="small" v-model="params.artist" placeholder="请选择"
								@change="search">
								<el-option v-for="item in artistList" :key="item.id" :label="item.name" :value="item.id"
									:clearable="true">
								</el-option>
							</el-select>
						</div>
						<div class="form_item">
							<el-input class="form_val" size="small" placeholder="搜索图片名称" suffix-icon="el-icon-search"
								v-model="params.name" @keyup.enter.native="search" :clearable="true">
							</el-input>
						</div>
					</div>
				</div>
				<draw-list v-if="list.length" :list="list" :type="tabC" @refreshBtn="getList"></draw-list>
				<empty-data v-else v-loading="loading" img='no_img_b.png' text='这里什么都没有哦~'
					:is_draw="tabC == 1 ? true : false" :is_cartoon="tabC == 2 ? true : false"></empty-data>
			</div>
			<div class="page_box mt20">
				<paging-page :total='total' align='right' @sizeChange='sizeChangeBtn'
					@currentChange='currentChangeBtn'></paging-page>
			</div>
		</div>
		<no-draw-times ref='dialogDraw' @renovate="getParams"></no-draw-times>

	</div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import DrawList from '../components/creationList/draw.vue'
import NoDrawTimes from '@/components/trial_expiration/no_draw_times.vue'//没有绘画次数

export default {
	components: { EmptyData, PagingPage, DrawList, EmptyData, NoDrawTimes },
	props: {},
	data() {
		return {
			tabs: [
				{
					label: 'AI背景',
					value: 1,
				},
				{
					label: 'AI换脸',
					value: 2,
				},
			],
			tabC: 1,
			params: {},
			page: 1,
			limit: 10,
			total: 0,
			list: [],
			draw_completed: 0, // 绘画已完成
			drawing: 0, // 绘画余额
			cartoon_completed: 0, // 漫画已完成
			cartoon: 0, // 漫画余额
			proportionList: [], // 画面比例
			statusList: [], // 状态
			artistList: [], // 艺术家
			styleList: [], // 风格
			loading: false

		}
	},
	computed: {},
	watch: {},
	created() {
		if (this.$route.query.type) {
			this.tabC = this.$route.query.type * 1
		}
		this.getParams()
		this.getList()
	},
	mounted() { },
	methods: {
		tabChange(item) {
			this.tabC = item.value
			this.resetFrom()
		},
		search() {
			this.page = 1
			this.list = []
			this.total = 0
			this.getList()
		},
		resetFrom() {
			this.params.proportion = ''
			this.params.style = ''
			this.params.artist = ''
			this.params.name = ''
			this.search()
		},
		sizeChangeBtn(val) {
			this.limit = val
			this.page = 1
			this.getList();
		},
		currentChangeBtn(val) {
			this.page = val;
			this.getList();
		},
		getList() {
			const data = {
				page: this.page,
				limit: this.limit,
				...this.params
			}
			this.loading = true

			if (this.tabC == 1) {
				this.$creationApi.getDrawList(data).then(res => {
					this.loading = false

					if (res.code == 1000) {
						this.list = res.result.data
						this.total = res.result.total
					} else {
						this.$errMsg(res.message)
					}
				})
			}
			if (this.tabC == 2) {
				this.$creationApi.getCartoonList(data).then(res => {
					this.loading = false

					if (res.code == 1000) {
						this.list = res.result.data
						this.total = res.result.total
					} else {
						this.$errMsg(res.message)
					}
				})
			}
		},
		getParams() {
			this.$creationApi.getDrawParams().then(res => {
				if (res.code == 1000) {
					this.draw_completed = res.result.draw_completed
					this.drawing = res.result.balance.drawing
					this.cartoon_completed = res.result.cartoon_completed
					this.cartoon = res.result.balance.cartoon
					// this.drawing = res.result.balance.drawing
					this.proportionList = res.result.proportion
					this.statusList = res.result.status
					this.artistList = res.result.artist
					this.styleList = res.result.style
				} else {
					this.$errMsg(res.message)
				}
			})
		},
		toBuy() {
            this.$refs.dialogDraw.open()
		}

	},
}
</script>

<style lang='scss' scoped>
@import '../creation.scss';

.draw_works {
	width: 100%;
	.draw_works_top {
		width: 100%;
		height: 80px;
		display: flex;
		padding: 10px;
		position: relative;
		background: url('~@/assets/images/creation/creationMng_(49).png') no-repeat;
		background-size: 100% 100%;

		.item_box {
			flex: 1;
			color: #FFFFFF;

			.title {
				text-align: center;
				font-size: 16px;
				font-weight: 600;
			}

			.num {
				font-size: 14px;
				justify-content: center;
				margin-top: 10px;

				div:nth-child(1) {
					margin-right: 100px;
				}
			}
		}

		.buy_tip {
			position: absolute;
			top: calc(50% - (38px / 2));
			left: calc((100% - 290px) / 2);
			width: 290px;
			height: 38px;
			background: #E8F0FE;

			border-radius: 21px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px;

			.txt {
				color: $blueColor1;
				font-size: 14px;
				margin-left: 10px;
			}
		}
	}
}
</style>
